import { useProjectPreviewStore } from "modules/organization/store";
import { useEffect, useState } from "react";
import OrgProjectPreviewWrapper from "../projectPreview/OrgProjectPreviewWrapper";
import OrgProjectPreviewPlugin from "../projectPreview/OrgProjectPreviewPlugin";
import NotFound from "modules/shared/components/NotFound";
import { Spin } from "antd";
import { useParams, useSearchParams } from "react-router-dom";
import { TokenService } from "modules/shared/services";
import EmbeddingNotAllowed from "modules/shared/components/EmbeddingNotAllowed";
import { getDomain } from "modules/shared/utility";

const OrgProjectIframePage = () => {
  const [isPageReady, setIsPageReady] = useState(false);
  const [currentSiteURL, setCurrentSiteURL] = useState('');
  const [isSiteAllowed, setIsSiteAllowed] = useState<boolean | null>(null);
  const { dataLoader, dataFetchError, jurisdictionData, getPreviewData, setDataLoader, allowedSites, getAllowedSites } =
    useProjectPreviewStore();
  const { project_slug } = useParams();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    if (isPageReady) {
      getPreviewData(project_slug as string).catch(console.error);
    }
  }, [getPreviewData, project_slug, isPageReady]);

  useEffect(() => {
    setDataLoader(true);
    const _tokenService = TokenService.getInstance();
    _tokenService.setToken(searchParams.get("token") as string);
    getAllowedSites(project_slug as string);
  }, [searchParams]);

  useEffect(() => {
    setCurrentSiteURL(document.referrer);
    if(allowedSites.length>0){
      if(allowedSites.includes('*.*')){
        setIsSiteAllowed(true);
        setIsPageReady(true);
      } else {
        setIsSiteAllowed(process.env.REACT_APP_ENVIRONMENT === 'local' ? true : allowedSites.includes(getDomain(document.referrer)));
        if(process.env.REACT_APP_ENVIRONMENT === 'local' ? true : allowedSites.includes(getDomain(document.referrer))){
          setIsPageReady(true);
        }
      }
      setDataLoader(false);
    }
  }, [allowedSites])
  
  console.log(document.referrer,process.env.REACT_APP_ENVIRONMENT)
  return (
    <Spin spinning={dataLoader}>
      <div style={{minHeight: 300}}>
        
          <main className="mainContent" style={{display: jurisdictionData ? 'block': 'none'}}>
            <div className="onlyPreviewSection">
              <OrgProjectPreviewWrapper>
                <OrgProjectPreviewPlugin />
              </OrgProjectPreviewWrapper>
            </div>
          </main>
        {dataFetchError && dataFetchError.code === 404 && <NotFound />}
        {(isPageReady === false) && !isSiteAllowed && (isSiteAllowed !== null) && <EmbeddingNotAllowed siteUrl={currentSiteURL}/>}
      </div>
    
    </Spin>
  );
};

export default OrgProjectIframePage;
